import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageButton, SigninSignup, User, GlobalFilter, FavoriteTeamsDropdown } from "../../components";
import { getUserPicture } from "../../services/identity/getUserPictureService";
import { getUserPoint } from "../../services/payment/userPointsService";
import { getNotification } from "../../services/notification/notificationService";
import { getNotificationCount } from "../../services/notification/notificationCountService";



function NavbarAboutUs() {

  const [extendNavbar, setExtendNavbar] = useState(false);
  const [userPictureData,setUserPictureData] = useState();
  const [userPointData, setUserPointData] = useState([]);
  const [notifications, setNotifications] = useState();
  const [notificationsCount, setNotificationsCount] = useState();

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const languageCode = pathSegments[1];

  const { i18n, t } = useTranslation();

  if (i18n.language !== languageCode) {
    i18n.changeLanguage(languageCode);
  };

  const fetchNotification = async () => {
    try {
      const page = 1;
      const perPage = 5;
      const res = await getNotification({ page , perPage });
      setNotifications(res?.data?.data?.data || []);     
    } catch (error) {
      console.log("fetchNotification",error);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const res = await getNotificationCount();
      setNotificationsCount(res?.data?.data || 0);        
    } catch (error) {
      console.log("UserPicture: ",error);
    }
  };

  const fetchUserPicture = async () => {
    try {
      const res = await getUserPicture();
      setUserPictureData(res?.data?.data);
      
    } catch (error) {
      console.log("UserPicture: ",error);
    }
  };

  const fetchUserPoint = async () => {
    try {

      const res = await getUserPoint();
  
      setUserPointData(res?.data?.data || []);
    } catch (error) {
      console.log("Ads Error:",error);
    }
  }

  useEffect(()=> {
    fetchUserPicture();
    fetchUserPoint();
    fetchNotification();
    fetchNotificationCount();
  },[])

  return (
    <NavbarContainer extendNavbar={extendNavbar} className="navbar">
      <NavbarInnerContainer>
        <NavbarLeftContainer>
          <NavbarLinkToImg to="/">
            <Logo src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/c039dc19-4d88-4b95-0180-445a54c94c00/original"></Logo>
          </NavbarLinkToImg>
        </NavbarLeftContainer>

        <NavbarRightContainer>
          <NavbarLinkContainer>
            
            <NavbarLink href="#what">{t("whatIsIt")}</NavbarLink>
            <NavbarLink href="#support-conditions">{t("supportConditions")}</NavbarLink>
            <NavbarLink href="#how">{t("how")}</NavbarLink>
            <NavbarLink href="#features">{t("features")}</NavbarLink>
            <NavbarLink href="#download">{t("download")}</NavbarLink>
            <NavbarLink href="#questions">{t("faq")}</NavbarLink>
          </NavbarLinkContainer>

            <RightMenuContainer>
              <RightMenuSubContainer>
                <LanguageButton />
                <FavoriteTeamsDropdown/>
                <SigninSignup 
                children={t("login")}
                />
                <User 
                  userPictureData={userPictureData}
                  userPoint={userPointData}
                  notifications={notifications}
                  notificationsCount={notificationsCount}
                />
              </RightMenuSubContainer>
              
              
                <GlobalFilter />
                     
            </RightMenuContainer>

            <OpenLinksButton
              onClick={() => {
                setExtendNavbar((curr) => !curr);
              }}
            >
              {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
            </OpenLinksButton>
          
        </NavbarRightContainer>
      </NavbarInnerContainer>

      {extendNavbar && (
        <NavbarExtendedContainer>

          <NavbarLinkExtendedTo to="/">HomePage</NavbarLinkExtendedTo>
          <NavbarLinkExtended href="#what">{t("whatIsIt")}</NavbarLinkExtended>
          <NavbarLinkExtended href="#support">{("supportConditions")}</NavbarLinkExtended>
          <NavbarLinkExtended href="#how">{t("how")}</NavbarLinkExtended>
          <NavbarLinkExtended href="#features">{t("features")}</NavbarLinkExtended>
          <NavbarLinkExtended href="#download">{t("download")}</NavbarLinkExtended>
          <NavbarLinkExtended href="#questions">{t("Faq")}</NavbarLinkExtended>

        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  );
}



export default NavbarAboutUs 

 
export const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${(props) => (props.extendNavbar ? "100vh" : "60px")};
  background-color: var(--bg-navbar);
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: height 0.3s;
  
  @media (min-width: 700px) {
    height: 60px;
  }
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
`;

export const NavbarLeftContainer = styled.div`
  flex: 30%;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  margin: 0 30px;
  max-width: 180px;
  height: auto;
`;

export const NavbarRightContainer = styled.div`
  flex: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    flex: 1%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const NavbarLinkContainer = styled.div`
  display: flex;

  @media (min-width: 700px) {
    width: 100%;
  }
`;

export const NavbarLink = styled.a`
  color: #C0C0C0;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  @media (max-width: 700px) {
    display: none;
  }
`;


export const NavbarLinkTo = styled(Link)`
  color: #C0C0C0;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const NavbarLinkToImg = styled(Link)`
  
`;

export const OpenLinksButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;

  @media (min-width: 700px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 700px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled.a`
  color: white;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const NavbarLinkExtendedTo = styled(Link)`
  color: white;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const RightMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RightMenuSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 900px) {
    display: none;
  }
`;