import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import NotFound from '../not-found/NotFound';
import { useTranslation } from 'react-i18next';
import { Spoiler, Skeleton } from '@mantine/core';
import { getNotification } from '../../services/notification/notificationService';
import { DateTimeFormatter } from '../../components';

const Notifications = () => {

  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(true);

  const originalLanguage = navigator.language;
  const newLanguage = originalLanguage.split('-')[0];
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const userLanguage = storedLanguage ? storedLanguage : newLanguage;

  const fetchNotification = async () => {
    try {
      const page = 1;
      const perPage = 5;
      const res = await getNotification({ page , perPage });
      setNotificationData(res?.data?.data?.data || []);
      setLoading(false);     
    } catch (error) {
      console.log("fetchNotification",error);
      setLoading(false);
    }
  };

  console.log("t",notificationData);
  const { t } = useTranslation();


  // const notificationsData = [
  //   {
  //     id: 1,
  //     title: t("conditionalDonationOpportunity"),
  //     description: t("conditionalDonationDescription"),
  //     date: '2024-09-10',
  //     image: 'https://via.placeholder.com/150', // Example image URL
  //   },
  //   {
  //     id: 2,
  //     title: t("matchAndSuccessSupport"),
  //     description: t("matchAndSuccessSupportDescription"),
  //     date: '2024-09-12',
  //     image: 'https://via.placeholder.com/150', // Example image URL
  //   },
  //   {
  //     id: 3,
  //     title: t("transferSupportHasBegun"),
  //     description: t("transferSupportDescription"),
  //     date: '2024-09-15',
  //     image: 'https://via.placeholder.com/150', // Example image URL
  //   },
  // ];

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <NotificationsMain>
      <CardContainer>
        {loading ? (
          [...Array(5)].map((_, index) => (
            <NotificationCard key={index}>
              <Skeleton height={200} mb="sm" />
              <Skeleton height={20} width="80%" mb="sm" />
              <Skeleton height={50} width="100%" />
            </NotificationCard>
          ))
        ) : notificationData.length > 0 ? (
          notificationData.map(notification => (
            <NotificationCard key={notification?.id}>
              <ImageContainer>
                <img src={notification?.imageUrl} alt={notification?.title} />
              </ImageContainer>
              <CardContent>
                <Title>{notification?.title}</Title>
                <Spoiler maxHeight={80} showLabel={t('showMore')} hideLabel={t('showLess')}>
                  <p>{notification?.body}</p>
                  <small><DateTimeFormatter apiTarih={new Date(notification.createdAt || "")} dil={userLanguage} /></small>
                </Spoiler>
              </CardContent>
            </NotificationCard>
          ))
        ) : (
          <NotFound text={`${t('searchResults')} ${t('notFound')}`} />
        )}
      </CardContainer>
    </NotificationsMain>
  );
};

export default Notifications;

export const NotificationsMain = styled.main`
  background-color: #f8f8f8;
  padding: 80px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const NotificationCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 0;
  width: 100%;
  max-width: 370px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  }
`;

export const ImageContainer = styled.div`
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 20px 0;
    background-color: #033036;
  }
`;

export const CardContent = styled.div`
  padding: 20px;

  h3 {
    font-size: 1.4em;
    margin: 0;
    color: #333;
  }

  p {
    font-size: 1em;
    color: #555;
    margin: 10px 0 15px;
  }

  small {
    font-size: 0.85em;
    color: #888;
  }
`;
export const Title = styled.h3`
  font-size: 1.4em;
  margin: 0;
  color: #333;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 600px) {
    width: 90%; // Adjust width as needed
  }
`;