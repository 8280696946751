import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink, Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageButton, SigninSignup, User, GlobalFilter, FavoriteTeamsDropdown } from "../../components";
import { FaList } from "react-icons/fa";
import { BsChevronRight, BsCalendar2Date } from "react-icons/bs";
import { HiOutlineDownload } from "react-icons/hi";
import { IoNewspaperOutline } from "react-icons/io5";
import { GrCertificate } from "react-icons/gr";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { GiStarsStack, GiWorld, GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose, IoMdNotifications, IoMdHelp, IoMdPlayCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  FaInstagram,
  FaFacebookSquare,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { getSports } from "../../services/sport/sportsService";
import { getUserPicture } from "../../services/identity/getUserPictureService";
import { setCountryId, setSportId, setTeamId, setTournamentId } from "../../redux/features/filter/filterSlice";
import { getUserPoint } from "../../services/payment/userPointsService";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";
import { getNotification } from "../../services/notification/notificationService";
import { getNotificationCount } from "../../services/notification/notificationCountService";


const sportTypes = {
  '6498b1e91d6341510544c3a7': 'footballUrl',
  '6498b1e91d6341510544c3a8': 'basketballUrl',
  '6498b1e91d6341510544c3a9': 'volleyballUrl',
};

const getSportTitle = (sportId) => {
  return sportTypes[sportId] || "";
};

function NavbarSubHome() {

  const SPORT_ID_KEY = "selectedSportId";
  
  const [sportType, setSportType] = useState([]);
  const [userPictureData,setUserPictureData] = useState();
  const [notifications, setNotifications] = useState();
  const [extendNavbar, setExtendNavbar] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [userPointData, setUserPointData] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState();

  const {sportData} = useSelector((state) => state.filter);

  const sortedSportData = sportData ? [...sportData].sort((a, b) => a.enumId - b.enumId) : [];

  const { t } = useTranslation();
  const {name} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startLanguage = getStartLanguage();

  const iconStyle = {
    color: "#c0c0c0",
    marginLeft: "10px",
  };

  const handleHelpClick = () => {
    if (isActive === "help") {
      setIsActive(null);
    } else {
      setIsActive("help");
    }
  };

  const fetchUserPicture = async () => {
    try {
      const res = await getUserPicture();
      setUserPictureData(res?.data?.data);
      
    } catch (error) {
      console.log("UserPicture: ",error);
    }
  };

  const fetchNotification = async () => {
    try {
      const page = 1;
      const perPage = 5;
      const res = await getNotification({ page , perPage });
      setNotifications(res?.data?.data?.data || []);     
    } catch (error) {
      console.log("fetchNotification",error);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const res = await getNotificationCount();
      setNotificationsCount(res?.data?.data || 0);        
    } catch (error) {
      console.log("UserPicture: ",error);
    }
  };

  const fetchSportService = async () => {
    try {
        const res = await getSports();
        const sortedSports = res?.data?.data?.sort((a, b) => a.enumId - b.enumId);
        setSportType(sortedSports || []);
    } catch (error) {
        console.log("Sport Error:", error);
    }
  };

  const selectSportID = async (e) => {
    const newSportId = e.target.value;
    const selectedSport = e.target.options[e.target.selectedIndex].text;
    const formattedSport = RemoveTurkishChars({ text: selectedSport });
    localStorage.setItem("selectedSportName",formattedSport);
    localStorage.setItem(SPORT_ID_KEY, newSportId);
    localStorage.removeItem("selectedTeam");
    dispatch(setSportId(newSportId));
    dispatch(setCountryId(""));
    dispatch(setTournamentId(""));
    dispatch(setTeamId(""));
    const url  = `/${startLanguage}/${t(getSportTitle(newSportId))}`;
    
    window.location.href = url;
  };

    const handleLogo = () => {
      localStorage.removeItem(SPORT_ID_KEY);
      dispatch(setSportId(""));
      dispatch(setCountryId(""));
      dispatch(setTournamentId(""));
    }

    const fetchUserPoint = async () => {
      try {
  
        const res = await getUserPoint();
    
        setUserPointData(res?.data?.data || []);
      } catch (error) {
        console.log("Ads Error:",error);
      }
    };

    const newsFromUs = t("newsFromUs"); 
    const formattedNewsFromUs = RemoveTurkishChars({ text: newsFromUs }).replace(/ /g, "").toLowerCase();

    const menuItems = [
      {
        icon: <IoSettingsOutline size={20}/>,
        label: t("myAccount"),
        link: `/${startLanguage}/${RemoveTurkishChars({ text: t("accountSettings") })}`,
      },
      {
        icon: <IoMdHelp size={32} />,
        label: t("myJoinedCampaigns"),
        link: `/${startLanguage}/${RemoveTurkishChars({ text: t("myJoinedCampaigns") })}`,
      },
      {
        icon: <GrCertificate size={32} />,
        label: t("myCertificates"),
        link: `/${startLanguage}/${RemoveTurkishChars({ text: t("myCertificates") })}`,
      },
      {
        icon: <MdOutlineCheckCircleOutline size={32}/>,
        label: t("favouriteTeams"),
        link: `/${startLanguage}/${RemoveTurkishChars({ text: t("favouriteTeams") })}`,
      },
      {
        icon: <GiWorld size={32} />,
        label: t("fansupportSocial"),
        link: "/",
      },
      {
        icon: <IoMdNotifications size={32} />,
        label: t("notifications"),
        link: `/${startLanguage}/${RemoveTurkishChars({ text: t("notifications") })}`,
      },
      {
        icon: <IoNewspaperOutline size={32} />,
        label: t("newsFromUs"),
        link: `/${startLanguage}/${formattedNewsFromUs}`,
      },
      {
        icon: <IoMdHelp size={32} />,
        label: t("help"),
        link: `/${startLanguage}/${RemoveTurkishChars({ text: t("help") })}`,
        onClick: handleHelpClick,
      },
    ];

    const handleLinkClick = () => {
      setExtendNavbar(false);
    };

    useEffect(() => {
      fetchSportService();
      fetchUserPicture();
      fetchUserPoint();
      fetchNotification();
      fetchNotificationCount();
    }, [])
  
  return (
    <NavbarSubContainer extendNavbar={extendNavbar} className="navbar">
      <NavbarInnerContainer>

        <OpenLinksButton
          onClick={() => {
            setExtendNavbar((curr) => !curr);
          }}
        > 
          {extendNavbar ? <> <IoMdClose size={32} style={iconStyle}/> </> : <> <GiHamburgerMenu size={32} style={iconStyle}/> </>}
        </OpenLinksButton>

        <NavbarLeftContainer>

          <NavbarLinkToImg onClick={handleLogo} to="/">
            <Logo extendNavbar={extendNavbar} src="https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/c039dc19-4d88-4b95-0180-445a54c94c00/original"></Logo>
          </NavbarLinkToImg>

          <FilterSelect onChange={selectSportID}>
              <FilterOption selected={!selectSportID}>{t("sportType")}</FilterOption>
              {sortedSportData?.length ? sortedSportData.map((sport) => (
                <FilterOption value={sport.id} key={sport.id} selected={sport.id === selectSportID || sport.id === localStorage.getItem(SPORT_ID_KEY)}>
                  {sport.name}
                </FilterOption>
              )) : <FilterOption disabled selected>{t("Not found..")}</FilterOption>}
            </FilterSelect>

        </NavbarLeftContainer>

        <NavbarRightContainer>
          <NavbarLinkContainer>


            <NavbarLink 
            to={`/${startLanguage}/${localStorage.getItem("selectedSportName")}/${RemoveTurkishChars({ text: t("teams") })}`}
            exact 
            activeClassName="active"
            >
              {t("teams")}
            </NavbarLink>

            <NavbarLink 
            to={`/${startLanguage}/${localStorage.getItem("selectedSportName")}/${RemoveTurkishChars({ text: t("standings") })}`} 
            activeClassName="active"
            >
              {t("standings")}
            </NavbarLink>

            <NavbarLink 
            to={`/${startLanguage}/${localStorage.getItem("selectedSportName")}/${RemoveTurkishChars({ text: t("fixture") })}`} 
            activeClassName="active">
              {t("fixture")}
            </NavbarLink>

          </NavbarLinkContainer>
            
            <RightMenuContainer>
              <RightMenuSubContainer>
                <LanguageButton minWidth="none" />              

                
                <FavoriteTeamsDropdown/>
                <User 
                  userPictureData={userPictureData}
                  userPoint={userPointData}
                  notifications={notifications}
                  notificationsCount={notificationsCount}
                />
                <SigninSignup 
                children={t("login")}
                />
              </RightMenuSubContainer>
              
              
              <GlobalFilter />
                     
            </RightMenuContainer>

            
          
        </NavbarRightContainer>

        

      </NavbarInnerContainer>

      {extendNavbar && (
        <NavbarExtendedContainer>


          <MenuContainer>

            <MenuItemDownload>
              <MenuItemContainer>
                <NavbarLinkIconContainer>
                  <HiOutlineDownload size={32} />
                  <NavbarLinkDownload to="https://fansupport.com/app" activeClassName="active">
                    {t("Download App")}
                  </NavbarLinkDownload>
                </NavbarLinkIconContainer>
                <IoMdPlayCircle size={25} />
              </MenuItemContainer>
            </MenuItemDownload>



            <MenuItem>
              <MenuItemContainer>
                <NavbarLinkIconContainer>
                  <GiStarsStack size={32} />
                  <NavbarLink to={`/${startLanguage}/${localStorage.getItem("selectedSportName")}/${RemoveTurkishChars({ text: t("teams") })}`} onClick={handleLinkClick} exact activeClassName="active">
                    {t("teams")}
                  </NavbarLink>
                </NavbarLinkIconContainer>
                <BsChevronRight size={25} />
              </MenuItemContainer>
            </MenuItem>
            
            <MenuItem>
              <MenuItemContainer>
                <NavbarLinkIconContainer>
                <FaList size={32} />
                <NavbarLink to={`/${startLanguage}/${localStorage.getItem("selectedSportName")}/${RemoveTurkishChars({ text: t("standings") })}`} onClick={handleLinkClick} activeClassName="active">
                  {t("standings")}
                </NavbarLink>
                </NavbarLinkIconContainer>
                <BsChevronRight size={25} />
              </MenuItemContainer>
            </MenuItem>

            <MenuItem>
              <MenuItemContainer>
                <NavbarLinkIconContainer>
                <BsCalendar2Date size={32}  />
                <NavbarLink to={`/${startLanguage}/${localStorage.getItem("selectedSportName")}/${RemoveTurkishChars({ text: t("fixture") })}`} onClick={handleLinkClick} activeClassName="active">
                  {t("fixture")}
                </NavbarLink>
                </NavbarLinkIconContainer>
                <BsChevronRight size={25} />
              </MenuItemContainer>
            </MenuItem> 

            {menuItems?.map((item, index) => (
              <MenuItem key={index} onClick={item.onClick}>
                <MenuItemContainer>
                  <NavbarLinkIconContainer>
                    {item.icon}
                    <NavbarLink to={item.link} onClick={handleLinkClick} activeClassName="active">
                      {t(item.label)}
                    </NavbarLink>
                  </NavbarLinkIconContainer>
                  <BsChevronRight size={25} />
                </MenuItemContainer>
              </MenuItem>
            ))} 

          </MenuContainer>

          

          <MenuBottomContainer>

            <MenuItem>
              <MenuItemContainer>           
                <NavbarLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("login") })}`} activeClassName="active">
                  <NavbarLinkIconLoginContainer>
                    <SigninSignup 
                    fontSize="large"
                    children={t("Sign In / Sign Up")}
                    >                      
                    </SigninSignup>
                    <User />  
                  </NavbarLinkIconLoginContainer>
                </NavbarLink>                
                <BsChevronRight size={25} />
              </MenuItemContainer>
            </MenuItem>

            <LanguageButtonContainer>
              <LanguageButton 
              border="1px solid grey"
              />
            </LanguageButtonContainer>

            <FooterSocialMedia>
                <FooterSocialMediaLink to="https://www.instagram.com/fansupport.tr/" target="_blank">
                  <FaInstagram style={iconStyle} size={20} />
                </FooterSocialMediaLink>
                <FooterSocialMediaLink to="https://www.facebook.com/fansupport.tr" target="_blank">
                  <FaFacebookSquare style={iconStyle} size={20} />
                </FooterSocialMediaLink>
                <FooterSocialMediaLink to="https://twitter.com/fansupporttr" target="_blank">
                  <FaTwitter style={iconStyle} size={20} />
                </FooterSocialMediaLink>
                <FooterSocialMediaLink to="https://www.linkedin.com/company/fansupport/" target="_blank">
                  <FaLinkedin style={iconStyle} size={20} />
                </FooterSocialMediaLink>        
              </FooterSocialMedia>
       
          </MenuBottomContainer>


        </NavbarExtendedContainer>
      )}
    </NavbarSubContainer>
  );
}

export default NavbarSubHome;

export const NavbarSubContainer = styled.nav`
  position: fixed;
  top: 0;
  width: ${(props) => (props.extendNavbar ? "80%" : "100%")};
  height: ${(props) => (props.extendNavbar ? "100vh" : "60px")};
  background-color: var(--bg-navbar);
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: height 0.3s;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center; 
`;

export const NavbarLeftContainer = styled.div`
  flex: 30%;
  display: flex;
  align-items: center;
  gap: 70px;

  @media (max-width: 900px) {
    flex: 52%;
    justify-content: center;
  }
  @media (min-width: 900px) and (max-width: 1050px) {
    gap: 30px;
  }
`;

export const Logo = styled.img`
  margin: 0 30px;
  max-width: ${(props) => (props.extendNavbar ? "140px" : "180px")};
  height: auto;
`;

export const NavbarRightContainer = styled.div`
  flex: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex: 1%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const NavbarLinkContainer = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavbarLinkIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  gap: 10px;
  width: 100%;
`;

export const NavbarLinkIconLoginContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 21px;
  gap: 10px;
  width: 100%;
`;

export const NavbarLink = styled(NavLink)`
  width: 105px;
  text-align: center;
  text-transform: capitalize;
  color: #c0c0c0;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  &.active {
    color: #cf9224;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: start;
  }
  @media (min-width: 900px) and (max-width: 1150px) {
    width: auto;
    font-size: 11px;
  }
  @media (min-width: 1150px) and (max-width: 1330px) {
    width: auto;
    font-size: medium;
  }
`;
export const NavbarLinkDownload = styled(NavLink)`
  width: 105px;
  text-align: center;
  text-transform: capitalize;
  color: white;
  font-size: medium;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;

  &.active {
    color: #cf9224;
    font-weight: bold;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: start;
  }
`;

export const NavbarLinkToImg = styled(NavLink)``;

export const OpenLinksButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const RightMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RightMenuSubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 900px) {
    display: none;
  }
`;



export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-navbar);
  font-size: 18px;
  margin: 30px 0 0 0;
  height: 100vh;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled.a`
  color: white;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const NavbarLinkExtendedTo = styled(NavLink)`
  color: white;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const MenuContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 600px;

  @media (max-width: 376px) {
    max-height: 330px;
  };

  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

export const MenuBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 170px;
  border-top: 1px solid grey;
  border-radius: 30px 30px 0 0;
  background-color: #1d4146;
`;

export const MenuItem = styled.div`
  border-bottom: 1px solid grey;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 20px;
  gap: 10px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #cf9224;
  }
`;

export const MenuItemDownload = styled.div`
  border-bottom: 1px solid grey;
  align-items: center;
  text-decoration: none;
  color: white;
  // background: linear-gradient(270deg,#033036, #2ffcfa);
  // background: linear-gradient(270deg,#033036, #2ffcfa);
  // background: linear-gradient(270deg,#033036, #2ffceb);
  // background: linear-gradient(270deg,#033036, #b8ff53);
  // background: linear-gradient(270deg,#033036, #42ff4f);
  background: linear-gradient(270deg,#fec233, #033036);
  background: linear-gradient(151deg, rgb(254, 194, 51), rgb(203 34 118));
  background: linear-gradient(151deg, rgb(254, 194, 51), rgb(203, 34, 118));
  font-size: 20px;
  gap: 10px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #cf9224;
  }
`;

export const MenuItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  height: 60px;
`;

export const LanguageButtonContainer = styled.div`
  margin: 15px 10px;
`;

export const FooterSocialMedia = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 0 15px 0;
`;

export const FooterSocialMediaLink = styled(Link)`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const SubCategory = styled.div`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  margin-left: 20px;
`;

export const FilterSelect = styled.select`
  width: 100px;
  font-size: 15px;
  background-color: transparent;
  border: 0;
  color: white;

  @media (max-width: 900px) {
    display: none;
  }
  @media (min-width: 900px) and (max-width: 1070px) {
    width: auto;
    font-size: 12px;
  }
`;

export const FilterOption = styled.option`
  background-color: var(--bg-navbar); 
`;