import { fansupportPrivate } from "../index";

export const getNotificationChangeStatus = async (notificationId) => {
    return fansupportPrivate.put('/notification/change-status', 0, {
        headers: {
            'Content-Type': 'application/json', // JSON formatını belirt
        },
        params: { notificationId } // notificationId sorgu parametresi olarak gönderiliyor
    });
}
